<template>
  <div class="creationCompetitionTwo">
    <div class="creationCompetitionTwo-header">
      <span>选择竞赛内容</span>
      <el-select v-model="raceContents" @change="selectRace" placeholder="请选择" style="margin-left: 10px; width: 312px">
        <el-option v-for="item in options" :key="item.id" :label="item.tmpl_name" :value="item.id"> </el-option>
      </el-select>
    </div>
    <div class="creationCompetitionTwo-mid">
      <!--            <el-checkbox-group  v-model="checkedCities" @change="handleCheckAllChange">-->
      <!--                <el-checkbox v-for="(item,index) in templateItemList" :label="item.id" :key="item.id" disabled>-->
      <!--                    <div class="templateItem">-->
      <!--                        <span>{{item.tmpl_module_name}}</span>-->
      <!--                    </div>-->
      <!--                </el-checkbox>-->
      <!--            </el-checkbox-group>-->
      <div class="templateMain">
        <div class="templateItem" v-for="(item, index) in templateItemList" :key="index">
          <div>{{ item.tmpl_module_name }}</div>
        </div>
      </div>
    </div>
    <div class="upDownBtn">
      <el-button @click="toBack">上一步</el-button>
      <el-button @click="toNext" style="background: #1122d8; border-color: #1122d8; color: #ffffff; margin-left: 20px">下一步</el-button>
    </div>
  </div>
</template>

<script>
export default {
  name: "creationCompetitionTwo",
  data() {
    return {
      checkedCities: [1, 2],
      raceContents: "",
      options: [],
      templateItemList: [],
    };
  },
  created() {
    this.getTemplateList();
  },
  methods: {
    getTemplateList() {
      this.$http.axiosGet(this.$api.templateList, (res) => {
        if (res.code === 200) {
          this.options = res.data.data;
          if (res.data.data.length > 0) {
            if (this.$route.query.editForm) {
              let editForm = JSON.parse(this.$route.query.editForm);
              this.raceContents = editForm.tmp_id;
              this.selectRace(this.raceContents);
            } else {
              this.raceContents = res.data.data[0].id;
              this.selectRace(this.raceContents);
            }
          }
        } else {
          this.$message.warning(res.msg);
        }
      });
    },
    selectRace(val) {
      this.raceContents = val;
      this.$http.axiosGetBy(this.$api.templateList, { id: val }, (res) => {
        if (res.code === 200) {
          console.log(res);
          this.templateItemList = res.data.expand;
        } else {
          this.$message.warning(res.msg);
        }
      });
    },
    // handleCheckAllChange(val){
    //     console.log(59,val)
    // },
    toBack() {
      this.$router.go(-1);
    },
    toNext() {
      let para = this.$route.query.res;
      console.log(para);
      if (this.raceContents) {
        if (this.$route.query.editForm) {
          this.$router.push({
            path: "/schooladmin/competition/creationCompetitionthree",
            query: {
              resTwo: para,
              tmp_id: this.raceContents,
              editForm: this.$route.query.editForm,
              editRes: this.$route.query.editRes,
            },
          });
        } else {
          this.$router.push({
            path: "/schooladmin/competition/creationCompetitionthree",
            query: {
              resTwo: para,
              tmp_id: this.raceContents,
            },
          });
        }
      } else {
        this.$message.warning("请选择竞赛内容！");
      }
    },
  },
};
</script>

<style scoped lang="scss">
/deep/.el-checkbox {
  position: relative;
  margin: 0;
}
/deep/.el-checkbox__label {
  padding-left: 0;
}
/deep/.el-checkbox__input {
  position: absolute;
  bottom: -10%;
  left: 50%;
}
/*/deep/.el-checkbox-group .el-checkbox:nth-child(1) .el-checkbox__label .templateItem{*/
/*    background: url("./../../assets/images/competition/dianpuzhuangxiu.png") no-repeat;*/
/*}*/
/*/deep/.el-checkbox-group .el-checkbox:nth-child(2) .el-checkbox__label .templateItem{*/
/*    background: url("./../../assets/images/competition/kehufuwu.png") no-repeat;*/
/*}*/
/*/deep/.el-checkbox-group .el-checkbox:nth-child(3) .el-checkbox__label .templateItem{*/
/*    background: url("./../../assets/images/competition/yunyingtuiguang.png") no-repeat;*/
/*}*/
/*/deep/.el-checkbox-group .el-checkbox:nth-child(4) .el-checkbox__label .templateItem{*/
/*    background: url("./../../assets/images/competition/yunyingfenxi.png") no-repeat;*/
/*}*/
.creationCompetitionTwo {
  height: 100%;
  padding: 0 0 60px 30px;
  display: flex;
  flex-direction: column;
  .creationCompetitionTwo-header {
    padding-left: 60px;
    padding-top: 60px;
  }
  .creationCompetitionTwo-mid {
    /*margin: 0 auto;*/
    display: flex;
    width: 100%;
    margin-top: 24px;
    .templateMain{
            display: flex;
            justify-content: flex-start;
            width:100%;
            height: 30%;
            flex-wrap: wrap;
            .templateItem {
      width: 294px;
      height: 400px;
      position: relative;
      /*margin-left: 15px;*/
      transition: 0.5s;
      display: flex;
      &:hover {
        transform: scale(1.1, 1.1);
        cursor: pointer;
      }
      div {
        padding: 0 30px;
        position: absolute;
        text-align: center;
        bottom: 30px;
        left: 0;
        width: 100%;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 80px;
      }
    }
        }
      .templateItem:nth-child(6),
      .templateItem:nth-child(7) {
        position: relative;
        background-position: 17px 0 !important;
        &::before {
          content: "";
          position: absolute;
          width: 88%;
          height: 90%;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          opacity: .3;
          border-radius: 17px;
          box-shadow: 0 0 10px 1.5px #1122d8;
        }
      }
    .templateItem:nth-child(1) {
      background: url("./../../assets/images/competition/dianpuzhuangxiu.png") no-repeat;
    }
    .templateItem:nth-child(2) {
      background: url("./../../assets/images/competition/kehufuwu.png") no-repeat;
    }
    .templateItem:nth-child(3) {
      background: url("./../../assets/images/competition/yunyingtuiguang.png") no-repeat;
    }
    .templateItem:nth-child(4) {
      background: url("./../../assets/images/competition/yunyingfenxi.png") no-repeat;
    }
    .templateItem:nth-child(5) {
      background: url("./../../assets/images/competition/lilunkaoshi.png") no-repeat;
    }
    .templateItem:nth-child(6) {
      background: url("./../../assets/images/competition/Frame427318754.png") no-repeat;
    }
    .templateItem:nth-child(7) {
      background: url("./../../assets/images/competition/Frame427318755.png") no-repeat;
    }
  }
  .upDownBtn {
    margin-top: 120px;
    margin-left: 44%;
  }
}
</style>
